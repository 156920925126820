.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;  /* Preferred icon size */
  display: inline-block;
  line-height: 2;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.IconButton{
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
  margin: .5rem;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  color: #000;
  z-index: 2;
  user-select: none;
  vertical-align: top;
  overflow: visible;
  cursor: pointer;

  &:focus{
    outline: none;
  }

  ::before{
    content: "";
    display: block;
    position: absolute;
    height: 75%;
    width: 75%;
    left: 12.5%;
    top: 12.5%;
    z-index: -1;
    border-radius:100%;
    border: 1px solid black;

    transform: scale(1);
    transition: transform 100ms linear;
  }
  &:hover{
    ::before{
      transform: scale(1.125);
    }
  }
}
