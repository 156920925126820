.Loader{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #ffffff;
}
.LoadIcon{
  width: 2rem;
  height: 2rem;
}

.LoadIcon-Inner{
  width: 100%;
  height: 100%;

  border: 1px solid black;
  border-right-color: transparent;

  border-radius: 100%;

  @-webkit-keyframes rotor {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  @-moz-keyframes rotor {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  @-o-keyframes rotor {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  @keyframes rotor {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  -webkit-animation: rotor 2s infinite linear; /* Safari 4+ */
  -moz-animation:    rotor 2s infinite linear; /* Fx 5+ */
  -o-animation:      rotor 2s infinite linear; /* Opera 12+ */
  animation:         rotor 2s infinite linear; /* IE 10+, Fx 29+ */
}