.View-Wrapper{
    left: 0;
    top: 0;
    right: 0;
	position: fixed;
	height: 100%;
	padding-top:3rem;
	padding-left: .5rem;
	padding-right: .5rem;
	padding-bottom: .5rem;
	background-color: #ffffff;
	transition: transform 1000ms ease;
	z-index: 1000;
	@include media-breakpoint-up(md) {
		transition: left 1000ms ease, right 1000ms ease;
	}
	.Section-Header{
		padding-left: .5rem;
	}
}
.Studio-Wrapper {
	position: absolute;
	right: 0;
	top: 0;
	padding: 3rem;
	width: 100%;
	height: 100%;
	@include media-breakpoint-up(md) {
		width: 50%;
		overflow-x: auto;
		overflow-y: auto;
	}
}
.Info-Wrapper{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	padding: 3rem;
	width: 100%;
	@include media-breakpoint-up(md){
		width: 33.333%;
		overflow-x: auto;
		overflow-y: auto;
	}
	@include media-breakpoint-up(lg){
		width: 25%;
	}
}
.state-info .View-Wrapper{
	transform: translate(100%,0);
	display: block;
	@include media-breakpoint-up(md){
		transform: none;
		left: 33.333%;
		right: 0;
	}
	@include media-breakpoint-up(lg){
		left: 25%;
	}
}
.state-studio .View-Wrapper{
	transform: translate(-100%,0);
	display: block;
	@include media-breakpoint-up(md) {
		transform: none;
		right: 50%;
		left: 0;
	}
}

.Section-Header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height:3rem;
	line-height: 1.5;
	.page-title{
		margin-left: 1rem;
	}
	a:hover{
		text-decoration: none;
		color: inherit;
	}
}