.LoaderComponent, .CrashComponent {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	background: #ffffff;
}

.LoaderComponent{
}
.CrashComponent{
}
