.text-large{
    font-size: 2rem;
    font-weight: normal;
}
a{
    color: inherit;
    text-decoration: none;
    &:hover{
        color: inherit;
        text-decoration: underline;
    }
}
h1,h2,h3{
    font-size: 1rem;
    font-weight: bold;
}