.Studio-Content{
  position: relative;
  padding-bottom: 3rem;
  min-height: 100%;
  .TextColumn{
    margin-bottom: 3rem;
  }
  .Credits{
    position: absolute;
    bottom: -1rem;
    color: $gray-500;
    padding-bottom: 1rem;
  }
  blockquote{
    font-size: 2rem;
    line-height: 1.2;
    margin-top: .6rem;
  }
}
