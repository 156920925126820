.View-Content{
  position: relative;
  height: 100%;
  display: flex;
}

.Title-Container{
  height: 100%;
  width: auto;
  white-space: nowrap;
  .Info-Button{
    position: absolute;
    left: 0;
    display: flex;
    transition: top 600ms ease;
    .IconButton{
      margin-left: 0;
      margin-right: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.Title-Item{
  display: flex;
  width: 100%;
  padding-right: 2.5rem;
  text-decoration: none !important;
  span{
    transition: transform 300ms ease;
    margin-top: auto;
    margin-bottom: auto;
  }
  &:hover span{
    transform: translate(.5rem);
  }
  &.active span{
    transform: translate(2.5rem);
  }
}

.ImageView{
  width: 100%;
  height: 100%;
  position: relative;
}

.Image-Container{
  position: absolute;
  left: 1.5rem;
  right: 3rem;
  top: 3rem;
  bottom: 3rem;
  overflow: hidden;
  display: flex;
  .Image{
    background-color: $gray-200;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.ViewController{
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  .Prev-Area{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 25%;
    z-index: 100;
    cursor: w-resize;
  }
  .Next-Area{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 75%;
    z-index: 100;
    cursor: e-resize;
  }
}

.Horizontal-Rule{
  height: .75rem;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  &.bottom{
    top: auto;
    bottom: 0;
  }
}
.Horizontal-Mark{
  width: 0;
  height: 100%;
  position: relative;
  &:after{
    display: block;
    content: "";
    height: 100%;
    width:0;
    position: absolute;
    top: 0;
    left: 50%;
    border: .5px solid #000000;
  }
}

.Vertical-Rule{
  width: .75rem;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
}
.Vertical-Mark{
  width: 100%;
  height: 0;
  position: relative;
  &:after{
    position: absolute;
    display: block;
    content: "";
    height:0;
    width: 100%;
    top: 50%;
    right: 0;
    border: .5px solid #000000;
  }
}

.Position-Mark{
  position: absolute;
  transition: left 600ms ease, top 600ms ease, width 600ms ease, height 600ms ease;
  &:after{
    position: absolute;
    display: block;
    content: "";
    height:0;
    width: 2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,0);
    border: .5px solid #000000;
  }
  &:before{
    position: absolute;
    display: block;
    content: "";
    height:2rem;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(0,-50%);
    border: .5px solid #000000;
  }
}


.Thumbnails{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: #ffffff;
  .Thumbnails-Row{
    width: 100%;
    display: flex;
  }
  .Thumbnail{
    padding: .5vw;
    position: relative;
    width: 100%;
    height: 100%;
    img{
	    position: absolute;
	    top: 50%;
	    left: 50%;
      margin: auto;
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      padding: .5vw;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}