.Archiv{
  .Section-Header{
    padding-left: .5rem;
    position: sticky;
    z-index: 500;
    top: 0;
    background-color: #ffffff;
  }
  .Archiv-Entry{
    padding: 3rem;
    border-top: 1px solid black;
  }
  .Archiv-ImageContainer{
    margin-top: 2rem;
    margin-right: -1rem;
    }
  .Archiv-Image{
    display: inline-block;
    margin-bottom: 1rem;
    margin-right: 1rem;
    vertical-align: top;
    &.hochformat{
      width: 200px;
      height: auto;
    }
    &.querformat{
      width: 300px;
      height: auto;
    }
    @include media-breakpoint-down(sm){
      &.hochformat{
        max-width: 100%;
        height: auto;
      }
    }
  }
}